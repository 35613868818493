import styled from '@emotion/styled';
import { m } from 'framer-motion';
import Image from 'next/image';

import { Paragraph } from '@/components/common/MarkUp';
import { Stack } from '@/components/layout/Stack';
import notResults from '@/public/images/no-results.png';

export const NoResults = () => {
  return (
    <NoResDiv
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
      aria-live="assertive"
      aria-atomic
    >
      <Stack>
        <Paragraph size="subheading-lg" aria-live="polite" aria-atomic>
          Sorry, no results found.
        </Paragraph>
        <Paragraph size="subheading-sm">
          We couldn’t find what you are looking for. Please try another way.
        </Paragraph>

        <NoResultsImg role="presentation" alt="" src={notResults} />
      </Stack>
    </NoResDiv>
  );
};

const NoResDiv = styled(m.div)`
  margin: var(--spacing-medium) 0 auto;
  text-align: center;
`;

const NoResultsImg = styled(Image)`
  max-width: 200px;
  height: auto;
  margin: 0 auto;
`;
