import styled from '@emotion/styled';
import Link from 'next/link';

import { screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';
import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { Section } from '@/components/layout/Section';
import { bodyLarge } from '@/styles/typography';

import type { SanityBlogAuthorType } from '@/types/sanity';

interface SocialLinkProps {
  url: string;
  name: 'LinkedIn' | 'Email';
  text: string;
}

const SocialLink = ({ url, name, text }: SocialLinkProps) => {
  if (!url) {
    return null;
  }
  return (
    <Link href={url} aria-label={name}>
      <Icon icon={name} size="25px" />
      <span>{text}</span>
    </Link>
  );
};

export interface AuthorDetailsProps {
  author: SanityBlogAuthorType;
}

const AuthorDetails = ({ author }: AuthorDetailsProps) => {
  const { image, bio, email, linkedInUrl, name } = author;

  return (
    <Section
      backgroundColour="--background-warm-base"
      className="author-details"
    >
      <Container>
        {!!image && (
          <ImageContainer>
            <Image
              {...image}
              sizes="(max-width: 200px) 25vw, 200px"
              alt="Image of author"
            />
          </ImageContainer>
        )}
        <TextContainer>
          <Name size="subheading-sm">{name}</Name>
          <PortableText value={bio} />
          <SocialLinks>
            {!!linkedInUrl && (
              <div>
                <SocialLink
                  url={linkedInUrl}
                  name="LinkedIn"
                  text="Find me on LinkedIn"
                />
              </div>
            )}
            {!!email && (
              <div>
                <SocialLink
                  url={`mailto:${email}`}
                  name="Email"
                  text="Get in touch"
                />
              </div>
            )}
          </SocialLinks>
        </TextContainer>
      </Container>
    </Section>
  );
};

const Container = styled.div`
  display: block;
  padding: var(--spacing-100) var(--spacing-400);

  ${screen.sm} {
    display: flex;
    align-items: center;
  }

  ${screen.md} {
    padding: var(--spacing-small) var(--spacing-1600);
    margin: 0 var(--spacing-300);
  }
`;

const ImageContainer = styled.div`
  display: block;
  margin: 0px auto var(--spacing-medium);
  overflow: hidden;
  max-width: 120px;
  min-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${screen.sm} {
    flex-direction: column;
    margin: 0px;
    margin-right: var(--spacing-300);
    margin-top: var(--spacing-xx-small);
    flex-basis: 100%;
    max-width: 120px;
    min-width: 120px;
    width: 120px;
    height: 120px;
  }
`;

const Name = styled(Paragraph)``;

const TextContainer = styled.div`
  width: 100%;
  color: var(--text-strong);

  p {
    margin-bottom: var(--spacing-x-small);
    ${bodyLarge}
  }

  ${Name} {
    font-weight: var(--font-weight-semibold);
  }

  ${screen.sm} {
    width: auto;
    display: unset;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  a {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-100);
    align-items: center;
    width: fit-content;
    margin-right: var(--spacing-medium);
    text-decoration: none;
    color: var(--text-strong);
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
      text-underline-position: under;
      color: var(--text-action);
    }

    span {
      ${bodyLarge}
    }
  }
`;
export { AuthorDetails };
