import { useRef } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { screen } from '@/components/common/breakpoints';
import { IconOnlyButton } from '@/components/common/Buttons';
import { Paragraph } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';
import { AuthorDetails } from '@/components/page/blog/AuthorDetails';
import { BlogFilters as Filters } from '@/components/page/blog/BlogFilters';
import { ListingsHero } from '@/components/page/blog/ListingsHero';
import { Pagination } from '@/components/page/blog/Pagination';
import { NoResults } from '@/components/page/careers/NoResults';
import { CatalogueGrid } from '@/components/page/common/Catalogue/CatalogueGrid';
import { CatalogueCard } from '@/components/page/common/Catalogue/generateCatalogueCards';
import { subheadingSmall } from '@/styles/typography';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type {
  SanityBlogAuthorType,
  SanityBlogCategoryType,
  SanityCaseStudiesSettingsType,
} from '@/types/sanity';
import type {
  BlogPostPreview,
  BlogSettingsType,
  CaseStudyType,
} from '@/types/shared';
import type { FormEvent } from 'react';

export interface CatalogueProps {
  settings: BlogSettingsType | SanityCaseStudiesSettingsType;
  items: BlogPostPreview[] | CaseStudyType[];
  pageCount: number;
  postCount: number;
  pageType: 'category' | 'author' | 'blog' | 'case-studies';
  pageSubType?: string;
  filters?: SanityBlogCategoryType[];
  blogAuthor?: SanityBlogAuthorType;
}

interface FormElements extends HTMLFormControlsCollection {
  search: HTMLInputElement;
}
interface SearchFormElements extends HTMLFormElement {
  readonly elements: FormElements;
}

export const Catalogue = ({
  settings,
  filters,
  items,
  pageCount,
  postCount,
  pageType,
  pageSubType,
  blogAuthor,
}: CatalogueProps) => {
  const { heading, featuredImageDefault } = settings;

  const containerRef = useRef<HTMLDivElement>();
  const router = useRouter();
  const searchInputRef = useRef(null);

  const { query, asPath } = router;
  const page = Number(query.page ?? 1);
  const searchSubmit = (e?: FormEvent<SearchFormElements>) => {
    e?.preventDefault();

    router.push({
      pathname: asPath.split('?')[0],
      query: { search: e.currentTarget.elements.search.value },
    });
  };

  const {
    featuredPost: blogFeaturedPost,
    isFeaturedPost,
    authorImageDefault,
  } = settings as BlogSettingsType;

  const showFeaturedPost =
    page === 1 &&
    blogFeaturedPost !== undefined &&
    !!isFeaturedPost &&
    pageType !== 'author';

  const buildPageHeading = () => {
    if (query.search) {
      return generateSearchHeading();
    } else if (pageType === 'category') {
      const category = filters.find((c) => c.slug.current === pageSubType);
      const title = category?.name;
      return generateCategoryHeading(title);
    } else if (pageType === 'author') {
      return generateAuthorHeading(blogAuthor[0].name);
    } else return heading;
  };

  const handleRemoveTerm = () => {
    searchInputRef.current.value = '';
  };

  return (
    <>
      <ListingsHero
        key={pageType + query.search}
        heading={buildPageHeading()}
      />

      <Section
        className={`${pageType === 'case-studies' ? 'case-study' : 'blog'}-catalogue`}
        backgroundColour="--background-warm-base"
      >
        <Stack spacing={'--space-component-xl'}>
          {!!filters && (
            <CategoryContainer ref={containerRef}>
              <Filters label="Filter by blog category" options={filters} />
            </CategoryContainer>
          )}

          <SearchContainer>
            <form id="searchForm" onSubmit={searchSubmit}>
              <SearchInput
                type="text"
                placeholder="Search..."
                ref={searchInputRef}
                name="search"
                id="search"
                aria-label={`Search ${pageType === 'case-studies' ? 'Case Study' : 'Blog Posts'}`}
              />
              <SearchButton
                type="submit"
                aria-label="Search"
                icon="Search"
                variant="solid-dark"
                size="md"
              />
              <ClearButton
                onClick={handleRemoveTerm}
                title="Remove search term"
                icon="Close"
                size="md"
                variant="flat-light"
              />
            </form>
          </SearchContainer>
        </Stack>

        {query.search && postCount > 0 && (
          <Results size="body-lg" className="searchResultsText">
            {postCount} results for &apos;{query.search}&apos;
          </Results>
        )}
        {query.search && postCount === 0 && <NoResults />}
      </Section>
      {blogAuthor?.[0] && <AuthorDetails author={blogAuthor?.[0]} />}
      <>
        {items?.length >= 1 && (
          <>
            {!!showFeaturedPost && (
              <FeaturedContainer>
                <CatalogueCard
                  isFeaturePost={showFeaturedPost}
                  post={blogFeaturedPost}
                  fallbackImage={featuredImageDefault}
                  fallbackAuthorImage={authorImageDefault}
                />
              </FeaturedContainer>
            )}

            <CatalogueGrid
              posts={items}
              fallbackImage={featuredImageDefault}
              fallbackAuthorImage={authorImageDefault}
            />

            <Pagination pageCount={pageCount} />
          </>
        )}
      </>
    </>
  );
};

const FeaturedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: var(--spacing-300) 0 var(--spacing-500);

  ${screen.md} {
    margin: var(--spacing-300) 0 var(--spacing-500);
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xx-small) var(--spacing-x-small);

  ${screen.md} {
    flex-direction: row;
    justify-content: center;
  }
`;

const Results = styled(Paragraph)`
  margin-top: var(--spacing-small);

  text-align: center;
`;

const SearchContainer = styled.div`
  margin: 0 auto;
  display: flex;
  gap: var(--spacing-small);
  width: 100%;
  max-width: 480px;
  position: relative;

  form {
    position: relative;
    flex: 1;
  }
`;

const SearchInput = styled.input`
  ${subheadingSmall}
  width: 100%;
  border: 1px solid var(--border-action);
  line-height: 40px;
  color: var(--text-action);
  text-indent: 20px;
  border-radius: var(--radius-full);
`;

const ClearButton = styled(IconOnlyButton)`
  position: absolute;
  top: 2px;
  right: 3px;
  transform: scale(0.8);
`;
const SearchButton = styled(IconOnlyButton)`
  position: absolute;
  top: 2px;
  right: 38px;
  transform: scale(0.8);
`;

const generateCategoryHeading = (title: string): HeadingSanityBlockType => {
  const categoryHeading: HeadingSanityBlockType = [
    {
      _type: 'block',
      _key: '123',
      children: [
        {
          _key: 'e0ab5aa7e3eb',
          _type: 'span',
          marks: [],
          text: title,
        },
      ],

      style: 'normal',
    },
  ];

  return categoryHeading;
};

const generateSearchHeading = (): HeadingSanityBlockType => [
  {
    _type: 'block',
    _key: '321',
    children: [
      {
        _key: 'e0ab5aa7e3eb',
        _type: 'span',
        marks: [],
        text: 'Search results',
      },
    ],
    style: 'normal',
  },
];

const generateAuthorHeading = (name: string): HeadingSanityBlockType => {
  const authorHeading: HeadingSanityBlockType = [
    {
      _type: 'block',
      _key: '213',
      children: [
        {
          _key: 'e0ab5aa7e3eb',
          _type: 'span',
          marks: [],
          text: `Opinions from ${name}`,
        },
      ],
      style: 'normal',
    },
  ];

  return authorHeading;
};
