import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { IconOnlyButton } from '@/components/common/Buttons';
import { Paragraph } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';

export interface PaginationProps {
  pageCount: number;
}

const Pagination = ({ pageCount }: PaginationProps) => {
  const { query, asPath } = useRouter();

  const page = Number(query.page ?? 1);
  const search = query.search;

  const paginationControl = (direction: 'back' | 'next') => {
    const isControlVisible =
      (direction === 'back' && page > 1) ||
      (direction === 'next' && page < pageCount);

    if (!isControlVisible) return null;

    const newPage = direction === 'back' ? page - 1 : page + 1;

    const ariaLabel = direction === 'back' ? 'Previous' : 'Next';

    return (
      <IconOnlyButton
        href={{
          pathname: asPath.split('?')[0],
          query: search ? { page: newPage, search } : { page: newPage },
        }}
        aria-label={ariaLabel}
        icon={direction === 'back' ? 'ArrowLeft' : 'ArrowRight'}
        variant="solid-light"
        size="md"
      />
    );
  };

  return (
    <Section
      backgroundColour="--background-warm-base"
      verticalPadding
      className="pagination"
    >
      <PaginationContainer>
        {paginationControl('back')}
        <Paragraph size="body-lg">
          Page {page} of {pageCount}
        </Paragraph>
        {paginationControl('next')}
      </PaginationContainer>
    </Section>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  gap: var(--spacing-medium);
  justify-content: center;
  align-items: center;
`;

export { Pagination };
