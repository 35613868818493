import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { visuallyHiddenStyle } from '@/components/common/utils';
import { subheadingSmall } from '@/styles/typography';
import { transparentize } from '@/styles/utils';

import type { SanityBlogCategoryType } from '@/types/sanity';

export interface FilterOptionProps {
  item: SanityBlogCategoryType;
}

const FilterOption = ({ item }: FilterOptionProps) => {
  const isAll = item.name === 'All';

  const { asPath } = useRouter();
  const isCurrent = isAll
    ? asPath.endsWith('blog')
    : asPath.includes(item.slug.current);

  return (
    <FilterOptionWrapper isChecked={isCurrent}>
      <Link
        aria-label={`filter by ${item.name}`}
        href={{
          pathname: isAll ? '/blog' : `/blog/category/${item.slug.current}`,
        }}
      >
        <HiddenSpan>{isAll ? 'Show ' : 'Filter by '}</HiddenSpan>
        {item.name}
      </Link>
    </FilterOptionWrapper>
  );
};

export interface FiltersGroupProps {
  label: string;
  options: SanityBlogCategoryType[];
}

export const BlogFilters = ({ options, label }: FiltersGroupProps) => {
  return (
    <>
      <HiddenSpan id="filter-title">{label}</HiddenSpan>
      <FilterOption
        key="All"
        item={
          {
            name: 'All',
            slug: { current: '/blog', _type: 'slug' },
          } as SanityBlogCategoryType
        }
      />
      {options.map((value) => {
        return <FilterOption key={value._id} item={value} />;
      })}
    </>
  );
};

const HiddenSpan = styled.span`
  ${visuallyHiddenStyle}
`;

const FilterOptionWrapper = styled.div<{ isChecked: boolean }>`
  border: 0;
  background: transparent;
  padding: 0;
  color: inherit;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: inherit;
    text-decoration: none;
    ${subheadingSmall};
    background-color: ${({ isChecked }) =>
      isChecked ? 'var(--surface-primary-subtle)' : 'var(--transparent)'};
    padding: var(--spacing-100) var(--spacing-150);
    border-radius: var(--radius-xs);
    transition: background-color 0.3s ease-in-out;

    :hover {
      background-color: ${transparentize('--surface-primary-subtle', 70)};
    }
  }

  span {
    transition: all 0.2s ease-in;
  }

  /* hack to prevent layout shift when selecting filter */
  &::before {
    content: attr(data-text);
    display: block;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;

    @media speech {
      display: none;
    }
  }
`;
